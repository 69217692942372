<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_transacao"
  >
    <v-card class="expande-horizontal pa-3 wrap">
      <div class="expande-horizontal fonte px-1 pr-3 py-3">
        <h3 v-if="get_transacao.tipo_de_transacao === 'conta'">CONFIRMAR PAGAMENTO</h3>
        <h3 v-else>Visualizar Transação</h3>
        <v-spacer></v-spacer>

        <v-btn icon @click="fecha_modal_view_transacao">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="expande-horizontal pl-1 column">
        <span class="fonte" :style="`font-size: 2rem; color: #47cf72`">
          {{ $helper.formataSaldo(get_transacao.valor) }}
        </span>
        <h5 class="py-6 pb-0 fonte" style="color: #333">
          Anexe um comprovante de pagamento
        </h5>
        <modal-send-archive />
      </div>

      <div
        v-if="get_transacao.tipo_de_transacao === 'conta'"
        class="expande-horizontal"
      >
        <v-select
          label="Selecione a forma de pagamento"
          placeholder="ex: Dinheiro"
          v-model="get_transacao.forma_de_pagamento"
          :items="[
            'DINHEIRO',
            'PIX',
            'CARTÃO DE CRÉDITO',
            'CARTÃO DE DÉBITO',
            'BOLETO'
          ]"
        ></v-select>
      </div>
      <div class="expande-horizontal centraliza pa-3">
        <v-btn @click="atualizar_transacao" color="#47cf72" dark block>
          <span v-if="get_transacao.tipo_de_transacao === 'conta'">Confirmar Pagamento</span>
          <span v-else>Atualizar</span>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchiveFile.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_transacao",
      "get_transacaos",
      "get_modal_view_transacao",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_transacao",
      "atualizar_transacao",
      "fecha_modal_view_transacao"
    ])
  }
};
</script>
