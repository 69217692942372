<template>
  <v-flex class="pa-3 pt-0" :style="`max-height: 75vh; overflow: auto; background: ${$theme.background};`" xs12>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(transaction, i) in get_relatorios.docs"
        :key="`transac-${i}`"
      >
        <v-expansion-panel-header class="fonte pa-0 pr-3">
          <div class="expande-horizontal wrap">
            <v-flex xs12 md1>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" :color="$theme.secondary" class="mr-2"
                    >mdi-cash-multiple</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title
                      :style="`font-size: 10pt; color: ${$theme.secondary}`"
                      class="font-weight-bold"
                    >
                      #{{ transaction.id_transacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      {{ $moment(transaction.created_at).format("DD/MM/YY") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon v-if="transaction.caixa" size="15" class="mr-2"
                    >mdi-cash-register</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 10pt;">
                      {{
                        transaction.caixa && transaction.caixa.funcionario
                          ? transaction.caixa.funcionario.nome
                          : "conta"
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Caixa
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon
                    v-if="transaction.caixa && transaction.caixa.funcionario"
                    size="15"
                    class="mr-2"
                    >mdi-account-circle-outline</v-icon
                  >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 10pt;">
                      {{
                        transaction.compra && transaction.compra.cliente
                          ? transaction.compra.cliente.nome
                          : transaction.descricao
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Cliente
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" class="mr-2">mdi-cash</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      style="font-size: 10pt;"
                    >
                      {{ transaction.tipo_de_transacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Tipo de transação
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" class="mr-2">mdi-cash</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="green--text"
                      style="font-size: 10pt;"
                    >
                      {{
                        $helper.formataSaldo(
                          transaction.valor || transaction.compra.preco_total
                        )
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Valor
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md2>
              <div class="column expande-horizontal">
                <v-list-item>
                  <v-icon size="15" class="mr-2">mdi-flag</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      style="font-size: 10pt;"
                    >
                      {{ transaction.status_do_pagamento }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 8pt;">
                      Status do pagamento
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="expande-horizontal"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <ModalView />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalView from "../components/modalView.vue";
export default {
  data() {
    return {
      modal_escolha_template: false,
      item: null,
      template_escolhido: null,
      opcoes_possiveis: [
        {
          descricao: "Quebra de Linha",
          chave: "%0A"
        },
        {
          descricao: "Nome do cliente",
          chave: "item.cliente.nome"
        },
        {
          descricao: "Primeiro nome apenas",
          chave: "item.cliente.nome.split(' ')[0]"
        },
        {
          descricao: "Data do vencimento da fatura",
          chave: "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY')"
        }
      ]
    };
  },
  props: ["transacoes"],
  computed: {
    ...mapGetters(["get_relatorios"])
  },
  components: { ModalView },
  methods: {
    ...mapActions([
      "abre_modal_view_transacaos",
      "createConfirmAction",
      "createGlobalMessage"
    ]),
    chamarNoWhats(template) {
      if (this.item.cliente.whatsapp) {
        let item = this.item;
        let numero = item.cliente.whatsapp
          .split("")
          .filter(letra => letra !== " ");
        numero = numero.filter(letra => letra !== "(");
        numero = numero.filter(letra => letra !== ")");
        numero = numero.filter(letra => letra !== "-");
        numero = numero.join("");
        // numero = "96984196827";
        let mensagem = template.mensagem;
        let msg = mensagem.split(" ");
        let new_msg = "";

        msg.map(palavra => {
          if (palavra === "item.cliente.nome,") {
            new_msg = `${new_msg} ${item.cliente.nome},`;
          } else if (
            palavra ===
            "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY'),"
          ) {
            new_msg = `${new_msg} ${this.$moment(
              item.data_do_vencimento
            ).format("DD/MM/YYYY")},`;
          } else if (palavra === "item.cliente.nome.split(' ')[0],") {
            new_msg = `${new_msg} ${item.cliente.nome.split(" ")[0]},`;
          } else if (palavra === "item.cliente.nome") {
            new_msg = `${new_msg} ${item.cliente.nome}`;
          } else if (
            palavra ===
            "this.$moment(item.data_do_vencimento).format('DD/MM/YYYY')"
          ) {
            new_msg = `${new_msg} ${this.$moment(
              item.data_do_vencimento
            ).format("DD/MM/YYYY")}`;
          } else if (palavra === "item.cliente.nome.split(' ')[0]") {
            new_msg = `${new_msg} ${item.cliente.nome.split(" ")[0]}`;
          } else {
            new_msg = `${new_msg} ${palavra}`;
          }
        });

        window.open(`https://wa.me/55${numero}/?text=${new_msg}`, "_blank");
        this.item = null;
        this.modal_escolha_template = false;
      } else {
        this.createGlobalMessage({
          type: "warning",
          timeout: 4000,
          message: "O cliente não tem whatsapp cadastrado."
        });
      }
    }
  }
};
</script>
