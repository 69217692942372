<template>
  <div
    :style="`background: ${$theme.background};`"
    class="expande-horizontal fonte wrap pa-3 pb-0 pt-0"
  >
    <v-flex class="" xs12>
      <div class="expande-horizontal">
        <v-flex xs12 md3 class="pr-2 pb-0">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :color="$theme.secondary"
                v-model="get_transacoes_filtros.rangeDate"
                label="Filtre por data"
                readonly
                hide-details
                clearable
                @input="fire"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              dense
              small
              range
              rounded
              outlined
              :color="$theme.secondary"
              label="Selecione uma ou mais"
              clearable
              lang="pt-br"
              type="date"
              v-model="get_transacoes_filtros.rangeDate"
              @input="fire"
              hide-details
            >
            </v-date-picker>
          </v-menu>
        </v-flex>
        <!-- <v-btn
          @click="setTypeTransaction('conta')"
          rounded
          class="mr-3 elevation-0"
          :outlined="get_transacoes_filtros.tipo_de_transacao !== 'conta'"
          :color="
            get_transacoes_filtros.tipo_de_transacao === 'conta'
              ? $theme.secondary
              : 'grey lighten-3'
          "
        >
          <span
            class="mr-1"
            :class="{
              'grey--text':
                get_transacoes_filtros.tipo_de_transacao !== 'conta',
              'white--text font-weight-bold':
                get_transacoes_filtros.tipo_de_transacao === 'conta'
            }"
          >
            Contas
          </span>
          <v-icon
            v-if="get_transacoes_filtros.tipo_de_transacao !== 'conta'"
            size="18"
            color="grey"
          >
            mdi-close-circle-outline
          </v-icon>
          <v-icon v-else size="18" :color="$theme.primary">
            mdi-check-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          @click="setTypeTransaction('compra')"
          rounded
          class="elevation-0"
          :outlined="get_transacoes_filtros.tipo_de_transacao !== 'compra'"
          :color="
            get_transacoes_filtros.tipo_de_transacao === 'compra'
              ? $theme.secondary
              : 'grey lighten-3'
          "
        >
          <span
            class="mr-1"
            :class="{
              'grey--text':
                get_transacoes_filtros.tipo_de_transacao !== 'compra',
              'white--text font-weight-bold':
                get_transacoes_filtros.tipo_de_transacao === 'compra'
            }"
          >
            Vendas
          </span>
          <v-icon
            v-if="get_transacoes_filtros.tipo_de_transacao !== 'compra'"
            size="18"
            color="grey"
          >
            mdi-close-circle-outline
          </v-icon>
          <v-icon v-else size="18" :color="$theme.primary">
            mdi-check-circle-outline
          </v-icon>
        </v-btn> -->
      </div>
    </v-flex>
    <v-flex class="mt-6" xs12>
      <div class="expande-horizontal wrap pb-0 fonte centraliza">
        <!-- <v-flex v-if="$route.path === '/transacoes'" xs12 class="pr-2 pb-2" md3>
          <v-select
            rounded
            :color="$theme.secondary"
            hide-details
            label="Forma de pagamento"
            placeholder="ex: Dinheiro"
            v-model="get_transacoes_filtros.forma_de_pagamento"
            :items="['DINHEIRO', 'CRÉDITO', 'DÉBITO', 'PIX']"
            @input="debounceSearch"
            dense
            outlined
          ></v-select>
        </v-flex> -->

        <!-- <v-flex
          v-if="get_transacoes_filtros.tipo_de_transacao === 'conta'"
          xs12
          md3
          class="pr-2 pb-2"
        >
          <v-select
            dense
            outlined
            rounded
            :color="$theme.secondary"
            label="Filtrar por status"
            clearable
            v-model="get_transacoes_filtros.status_do_pagamento"
            @input="debounceSearch"
            hide-details
            :items="['pago', 'agendado', 'atrasado']"
          ></v-select>
        </v-flex> -->
      </div>
    </v-flex>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
export default {
  props: ['fire'],
  data() {
    return {
      menu: false,
      menu1: false,
      menu2: false,
      dates1: "",
      dates2: ""
    };
  },
  computed: {
    ...mapGetters([
      "get_clientes",
      "get_transacoes_filtros",
      "get_clientes_filtros"
    ]),
  },
  methods: {
    debounceSearch() {
      const x = _.debounce(this.fire, 800);
      x();
    },
  }
};
</script>
