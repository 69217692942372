var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","persistent":"","transition":"slide-x-transition"},model:{value:(_vm.get_modal_view_transacao),callback:function ($$v) {_vm.get_modal_view_transacao=$$v},expression:"get_modal_view_transacao"}},[_c('v-card',{staticClass:"expande-horizontal pa-3 wrap"},[_c('div',{staticClass:"expande-horizontal fonte px-1 pr-3 py-3"},[(_vm.get_transacao.tipo_de_transacao === 'conta')?_c('h3',[_vm._v("CONFIRMAR PAGAMENTO")]):_c('h3',[_vm._v("Visualizar Transação")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fecha_modal_view_transacao}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"expande-horizontal pl-1 column"},[_c('span',{staticClass:"fonte",style:(`font-size: 2rem; color: #47cf72`)},[_vm._v(" "+_vm._s(_vm.$helper.formataSaldo(_vm.get_transacao.valor))+" ")]),_c('h5',{staticClass:"py-6 pb-0 fonte",staticStyle:{"color":"#333"}},[_vm._v(" Anexe um comprovante de pagamento ")]),_c('modal-send-archive')],1),(_vm.get_transacao.tipo_de_transacao === 'conta')?_c('div',{staticClass:"expande-horizontal"},[_c('v-select',{attrs:{"label":"Selecione a forma de pagamento","placeholder":"ex: Dinheiro","items":[
          'DINHEIRO',
          'PIX',
          'CARTÃO DE CRÉDITO',
          'CARTÃO DE DÉBITO',
          'BOLETO'
        ]},model:{value:(_vm.get_transacao.forma_de_pagamento),callback:function ($$v) {_vm.$set(_vm.get_transacao, "forma_de_pagamento", $$v)},expression:"get_transacao.forma_de_pagamento"}})],1):_vm._e(),_c('div',{staticClass:"expande-horizontal centraliza pa-3"},[_c('v-btn',{attrs:{"color":"#47cf72","dark":"","block":""},on:{"click":_vm.atualizar_transacao}},[(_vm.get_transacao.tipo_de_transacao === 'conta')?_c('span',[_vm._v("Confirmar Pagamento")]):_c('span',[_vm._v("Atualizar")]),_c('v-icon',[_vm._v("mdi-check")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }